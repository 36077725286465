import request from "@/utils/request"

// phone、password、 saasAccount
export function login(data) {
  return request.post(`/login`, data)
}

export function getUser() {
  return request.get('/user/info')
}

export function getMenus() {
  return request.get('/menu/admin/my')
}

export function changePwd(oldPwd, newPwd) {
  return request.put('/user/change-pwd ', {
    oldPwd: oldPwd,
    newPwd: newPwd
  })
}
