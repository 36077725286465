export default [
  {
    path: '/admin/service-farmer',
    name: 'Farmers',
    component: () => import('./Farmers.vue'),
    meta: {
      title: '农户管理',
      activePath: '/admin/service-farmer',
      navMenu: [
        { title: '业务管理', path: '', },
      ]
    },
  },
  {
    path: '/admin/service-farmer/edit/:id?',
    name: 'FarmerEdit',
    component: () => import('./FarmerEdit.vue'),
    meta: {
      title: '用户编辑',
      activePath: '/admin/service-farmer',
      navMenu: [
        { title: '业务管理', path: '', },
        { title: '农户管理', path: '/admin/service-farmer', },
      ]
    },
  },
  {
    path: '/admin/service-farmer/info/:id',
    name: 'FarmerInfo',
    component: () => import('./FarmerInfo.vue'),
    meta: {
      title: '用户详情',
      parent: '业务管理',
      activePath: '/admin/service-farmer',
      navMenu: [
        { title: '业务管理', path: '', },
        { title: '农户管理', path: '/admin/service-farmer', },
      ]
    },
  },
  {
    path: '/admin/service-station',
    name: 'Stations',
    component: () => import('./Stations.vue'),
    meta: {title: '建站管理',parent: '业务管理'},
  },
  {
    path: '/admin/service-on-grid',
    name: 'Connections',
    component: () => import('./Connections.vue'),
    meta: {title: '并网管理',parent: '业务管理'},
  },
  {
    path: '/admin/service-order',
    name: 'Orders',
    component: () => import('./Orders.vue'),
    meta: {title: '工单管理',parent: '业务管理'},
  },
  {
    path: '/admin/service-construction',
    name: 'Construction',
    component: () => import('./Construction.vue'),
    meta: {
      title: '工单管理',
      parent: '业务管理',
      activePath: '/admin/service-order',
    },
  },
]