export default [
  {
    path: '/admin/grid-connection',
    name: 'gridConnectionList',
    component: () => import('./List.vue'),
    meta: {title: '并网管理', parent: '业务管理'},
  },
  {
    path: '/admin/grid-connection/info/:id',
    name: 'gridConnectionInfo',
    component: () => import('./Info.vue'),
    meta: {
      title: '并网详情',
      parent: '业务管理',
      activePath: '/admin/grid-connection',
      navMenu: [
        { title: '业务管理', path: '', },
        { title: '并网管理', path: '/admin/grid-connection', },
      ]
    },
  },
  {
    path: '/admin/grid-connection/edit/:id',
    name: 'gridConnectionEdit',
    component: () => import('./Edit.vue'),
    meta: {
      title: '并网申请',
      parent: '业务管理',
      activePath: '/admin/grid-connection',
      navMenu: [
        { title: '业务管理', path: '', },
        { title: '并网管理', path: '/admin/grid-connection', },
      ]
    },
  },
]