export default [
  {
    path: '/admin/de-user',
    name: 'DeUsers',
    component: () => import('./DeUsers.vue'),
    meta: {title: '划转用户信息', parent: '划转收益管理'},
  },
  {
    path: '/admin/de-user/add',
    name: 'DeUserEdit',
    component: () => import('./DeUserEdit.vue'),
    meta: {
      title: '划转用户编辑',
      parent: '划转收益管理',
      activePath: '/admin/de-user',
      navMenu: [
        { title: '业务管理', path: '', },
        { title: '划转收益管理', path: '/admin/de-user', },
      ]
    },
  },
  {
    path: '/admin/de-user/edit/:id',
    name: 'DeUserEdit',
    component: () => import('./DeUserEdit.vue'),
    meta: {
      title: '划转用户编辑',
      parent: '划转收益管理',
      activePath: '/admin/de-user',
      navMenu: [
        { title: '业务管理', path: '', },
        { title: '划转收益管理', path: '/admin/de-user', },
      ]
    },
  },
  {
    path: '/admin/de-user/info/:id',
    name: 'DeUserInfo',
    component: () => import('./DeUserInfo.vue'),
    meta: {
      title: '划转用户详情',
      parent: '划转收益管理',
      activePath: '/admin/de-user',
      navMenu: [
        { title: '业务管理', path: '', },
        { title: '划转收益管理', path: '/admin/de-user', },
      ]
    },
  },
  {
    path: '/admin/de-user-see',
    name: 'DeUserSees',
    component: () => import('./DeUserSees.vue'),
    meta: {title: '划转对账查询', parent: '划转收益管理'},
  },
  {
    path: '/admin/de-user-examine',
    name: 'DeUserExamines',
    component: () => import('./DeUserExamines.vue'),
    meta: {title: '划转对账审核', parent: '划转收益管理'},
  },
  {
    path: '/admin/de-user-examine/edit/:id',
    name: 'DeUserExamineEdit',
    component: () => import('./DeUserExamineEdit.vue'),
    meta: {
      title: '划转对账审核编辑',
      parent: '划转对账审核',
      activePath: '/admin/de-user-examine',
      navMenu: [
        { title: '业务管理', path: '', },
        { title: '划转对账审核', path: '/admin/de-user-examine', },
      ]
    },
  },
]