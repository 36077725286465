<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import '@/styles/changeEle.scss';

export default {
  mounted() {
    let that = this
    that.$store.dispatch('auth/autoLogin').then(() => {
      if (that.$route.path == '/login') {
        that.$router.push('/admin/index')
      }
    }).catch(err => {
      console.log(err)
    })
  }
}
</script>

<style lang="scss">
#app {
  min-height: 100vh;
}

.el-button--primary{
  color: #FFFFFF !important;
  background-color: #0D6CE4 !important;
  border-color: #0D6CE4 !important;
}
.page-card .page-card-boody .table-action-item{
  color:#0D6CE4 !important;
}
</style>
