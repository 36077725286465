<template>
  <div class="navbar-container">
    <div class="navbar-title-wrap" style="display: flex; align-items: center;">
      <!--      <img src="@/assets/logo.png" style="width: 48px; height: 48px; padding: 10px; object-fit: fill;">-->
      <div class="navbar-title" style="line-height: 48px">
        {{ app_name }}
      </div>
    </div>
    <div class="navbar-right-wrap">
      <el-menu class="navbar-right-menu" mode="horizontal">
        <el-menu-item index="2" class="margin-right-20"
                      @click.native="goPage('/admin/monitorBoard')">监控大屏
        </el-menu-item>
        <el-submenu index="1" style="min-width: 150px;">
          <template slot="title"><span>{{ user.name }}</span></template>
          <el-menu-item @click.native="handleChangePwd" index="1-1">修改密码</el-menu-item>
          <el-menu-item @click.native="logout()" index="1-2">退出</el-menu-item>
        </el-submenu>
      </el-menu>
    </div>
    <!--修改密码-->
    <el-dialog title="修改密码" :visible.sync="pwd_dialog" append-to-body
               :close-on-click-modal="false" width="30%" :close-on-press-escape="false">
      <el-form :model="pwdForm" :rules="formRules" label-width="100px" ref="pwdForm"
               v-loading="form_loading">
        <el-form-item label="原密码" prop="oldPwd">
          <el-input v-model="pwdForm.oldPwd" type="password"></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="newPwd">
          <el-input v-model="pwdForm.newPwd" type="password"></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="confirmPwd">
          <el-input v-model="pwdForm.confirmPwd" type="password"></el-input>
        </el-form-item>
        <div class="dialog-footer">
          <el-button @click="pwd_dialog = false">取 消</el-button>
          <el-button @click="submitPwd('pwdForm')" type="primary">确 定</el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {APP_NAME} from "@/utils/config"
import {changePwd} from "@/api/auth";

export default {
  name: "Navbar",
  data() {
    let validatePaw = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.pwdForm.newPwd) {
        callback(new Error('两次输入密码不一致'));
      } else {
        callback();
      }
    };
    return {
      app_name: APP_NAME,
      form_loading: false,
      pwd_dialog: false,
      pwdForm: {
        oldPwd: '',
        newPwd: '',
        confirmPwd: '',
      },
      formRules: {
        oldPwd: [
          {required: true, message: '请输入原密码', trigger: 'blur'},
        ],
        newPwd: [
          {required: true, message: '请输入新密码', trigger: 'blur'},
        ],
        confirmPwd: [
          {required: true, message: '请输入确认密码', trigger: 'blur'},
          {validator: validatePaw, trigger: 'blur'}
        ],
      },
    }
  },
  components: {},
  computed: {
    user() {
      return this.$store.state.auth.user
    },
  },
  created() {

  },
  mounted() {

  },
  methods: {
    // 修改密码
    handleChangePwd() {
      this.pwdForm = {
        oldPwd: '',
        newPwd: '',
        confirmPwd: '',
      }
      this.pwd_dialog = true
    },
    submitPwd(formName) {
      let that = this
      that.$refs[formName].validate((valid) => {
        if (!valid) return false
        that.form_loading = true
        changePwd(that.pwdForm.oldPwd, that.pwdForm.newPwd).then(() => {
          that.pwd_dialog = false
          that.$message.success('修改成功')
          setTimeout(() => {
            that.logout()
          }, 1000)
        }).catch(() => {
          that.form_loading = false
        })
      })
    },
    //
    goPage(path) {
      window.open(`/#${path}`, '_blank')
    },
    logout() {
      let that = this
      that.$store.dispatch('auth/logout').then(() => {
        that.$router.replace('/admin/login')
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.navbar-container {
  padding: 0 20px;
  height: 64px;
  display: flex;
  justify-content: space-between;
  transform: translate3d(0, 0, 0);
  background-color: #FFFFFF;
  border: 1px solid #E6EBF0;

  .navbar-title-wrap {
    .navbar-title {
      font-size: 24px;
      color: #0D6CE4;
      font-weight: 600;
    }
  }

  .navbar-right-wrap {
    display: flex;
    align-items: center;

    /deep/ .el-menu.el-menu--horizontal {
      border-bottom-color: #ffffff00;
    }
  }

  /deep/ .el-menu--horizontal > .el-submenu .el-submenu__title:hover {
    background-color: unset;
  }

  /deep/ .el-menu--horizontal > .el-submenu.is-active .el-submenu__title,
  /deep/ .el-menu--horizontal > .el-menu-item.is-active {
    border-bottom: unset;
  }

  .navbar-right-menu {
    .margin-right-10 {
      margin-right: 10px;
    }

    /deep/ .el-menu-item, .el-submenu {
      height: 45px;
      line-height: 45px;
      color: #2F3133;
    }

    /deep/ .el-submenu__title {
      height: 45px !important;
      line-height: 45px !important;
      border-bottom: unset;
      color: #2F3133;
    }

    /deep/ .el-submenu__title:hover {
      color: #2F3133;
    }
  }
}
</style>
