export default [
  {
    path: '/admin/contract',
    name: 'contractList',
    component: () => import('./List.vue'),
    meta: {title: '合同管理', parent: '合同管理'},
  },
  {
    path: '/admin/contract/edit/:id',
    name: 'contractEdit',
    component: () => import('./Edit.vue'),
    meta: {
      title: '发起合同',
      parent: '合同管理',
      activePath: '/admin/contract',
      navMenu: [
        { title: '业务管理', path: '', },
        { title: '合同管理', path: '/admin/contract', },
      ]
    },
  },
  {
    path: '/admin/contract/info/:id',
    name: 'contractInfo',
    component: () => import('./Info.vue'),
    meta: {
      title: '合同详情',
      parent: '合同管理',
      activePath: '/admin/contract',
      navMenu: [
        { title: '业务管理', path: '', },
        { title: '合同管理', path: '/admin/contract', },
      ]
    },
  },
  {
    path: '/admin/contract/select',
    name: 'contractEdit',
    component: () => import('./Select.vue'),
    meta: {
      title: '发起合同',
      parent: '合同管理',
      activePath: '/admin/contract',
      navMenu: [
        { title: '业务管理', path: '', },
        { title: '合同管理', path: '/admin/contract', },
      ]
    },
  },
  {
    path: '/admin/contract/file',
    name: 'contractFile',
    component: () => import('./FileList.vue'),
    meta: {title: '合同文件', parent: '合同管理'},
  },
  {
    path: '/admin/contract/template',
    name: 'contractTemplate',
    component: () => import('./TemplateList.vue'),
    meta: {title: '模板管理', parent: '合同管理'},
  },
]