<template>
  <div class="app-wrapper">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "FullSecurityLayout",
  data() {
    return {}
  },
  created() {
    let that = this
    //  验证是否登录
    let loadingInstance = that.$loading()
    that.$store.dispatch('auth/autoLogin').then(() => {
      loadingInstance.close()
    }).catch((err) => {
      console.log(err)
      loadingInstance.close()
      that.$router.push({path: '/admin/login'})
    })
  },
}
</script>

<style scoped>

</style>