import Vue from 'vue'
import VueRouter from 'vue-router'
import BasicLayout from "@/layouts/BasicLayout"
import SecurityLayout from "@/layouts/SecurityLayout"
import FullSecurityLayout from "@/layouts/FullSecurityLayout.vue";

Vue.use(VueRouter)

import view_routes from '@/views/routes'

const routes = [
  {
    path: '/admin',
    name: 'Index',
    redirect: '/admin/dashboard',
    component: BasicLayout,
    meta: {title: '欢迎登录'},
    children: [
      {
        path: '/admin/login',
        name: 'Login',
        component: () => import('@/views/Login.vue'),
        meta: {title: '登录'},
      }
    ]
  },
  {
    path: '/admin',
    component: SecurityLayout,
    meta: {title: '首页'},
    children: [
      ...view_routes,
    ]
  },
  {
    path: '/admin',
    name: 'FullSecurityLayoutRoot',
    component: FullSecurityLayout,
    meta: {title: '首页'},
    children: [
      {
        path: '/admin/monitorBoard',
        name: 'MonitorBoard',
        component: () => import('@/views/index/MonitorBoard.vue'),
        meta: {title: '开发监控大屏'},
        children: [
          { path: '/admin/monitorBoard', redirect: '/admin/monitorBoard/china/0' },
          {
            path: '/admin/monitorBoard/china/:phase_code',
            name: 'China',
            component: () => import('@/views/map/china.vue'),
            meta: { title: '全国地图' }
          },
          {
            path: '/admin/monitorBoard/province/:phase_code',
            name: 'province', component: () => import('@/views/map/province.vue'),
            meta: { title: '全国地图' }
          },
          {
            path: '/admin/monitorBoard/city/:phase_code',
            name: 'city', component: () => import('@/views/map/city.vue'),
            meta: { title: '全国地图' }
          },
        ]
      }
    ]
  },
  {
    path: '*',
    // redirect: '/admin/exception/404'
    redirect: '/admin/dashboard'
  }
]

const router = new VueRouter({
  mode: 'hash',
  routes
})

export default router
