import {
  BASE_URI,
  CONSTRUCTION_ACCEPTANCE_STATUS, CONTRACT_MANAGEMENT_STATUS,
  DESIGN_MANAGEMENT_STATUS, GRID_CONNECTION_STATUS, PROJECT_MATERIAL_STATUS, SETTLEMENT_STATUS,
  TECHNICAL_SURVEY_STATUS,
  USER_MANAGEMENT_STATUS
} from "@/utils/config"

/**
 * 状态值映射
 * @param key
 * @param target
 * @param defaultValue
 * @returns {*|string}
 */
export const statusMapper = (key, target={}, defaultValue='') => target[key] || defaultValue

export default {
  getSourceUri(path) {
    if (/^http(s)?:\/\/.+/.test(path)) {
      return path
    }
    if (/^\/\/.+/.test(path)) {
      return path
    }
    return BASE_URI + (/^\//.test(path) ? path : '/' + path)
  },
  getSourceUris(paths) {
    let uris = []
    for (let i in paths) {
      uris.push(this.getSourceUri(paths[i]))
    }
    return uris
  }
}

/**
 * 用户管理状态
 */
export const taskStateName = state => statusMapper(state, USER_MANAGEMENT_STATUS, '-')

/**
 * 踏勘状态
 */
export const getTaskState = state => statusMapper(state, TECHNICAL_SURVEY_STATUS, '-')

/**
 * 施工验收状态
 */
export const constructionAcceptanceStatusMapper = state => statusMapper(state, CONSTRUCTION_ACCEPTANCE_STATUS, '-')

/**
 * 设计管理用户状态
 */
export const designStatusMapper = state => statusMapper(state, DESIGN_MANAGEMENT_STATUS, '-')

/**
 * 项目物料状态
 */
export const  projectMaterialStatusMapper = state => statusMapper(state, PROJECT_MATERIAL_STATUS, '-')

/**
 * 并网管理状态
 */
export const gridConnectionStatusMapper = state => statusMapper(state, GRID_CONNECTION_STATUS, '-')

/**
 * 结算管理状态
 */
export const settlementStatusMapper = state => statusMapper(state, SETTLEMENT_STATUS, '-')

/**
 * 合同管理状态
 */
export const getContractStatus = state => statusMapper(state, CONTRACT_MANAGEMENT_STATUS, '-')
