export default [
  {
    path: '/admin/organization',
    name: 'Organizations',
    component: () => import('./Organizations.vue'),
    meta: {title: '组织管理',parent: '系统设置'},
  },
  {
    path: '/admin/role',
    name: 'Roles',
    component: () => import('./Roles.vue'),
    meta: {title: '角色管理',parent: '系统设置'},
  },
  {
    path: '/admin/role-info/:id',
    name: 'RoleInfo',
    component: () => import('./RoleInfo.vue'),
    meta: {title: '角色详情',parent: '系统设置'},
  },
  {
    path: '/admin/role-add/:id?',
    name: 'RoleAdd',
    component: () => import('./RoleAdd.vue'),
    meta: {
      title: '新增角色',
      parent: '角色管理',
      activePath: '/admin/role',
      navMenu: [
        { title: '业务管理', path: '', },
        { title: '角色管理', path: '/admin/role', },
      ]
    },
  },
  {
    path: '/admin/authority',
    name: 'Authorities',
    component: () => import('./Authorities.vue'),
    meta: {title: '权限管理',parent: '系统设置'},
  },
  {
    path: '/admin/authority/:id',
    name: 'AuthorityEdit',
    component: () => import('./AuthorityEdit.vue'),
    meta: {
      title: '编辑权限',
      parent: '权限管理',
      activePath: '/admin/authority',
      navMenu: [
        { title: '业务管理', path: '', },
        { title: '权限管理', path: '/admin/authority', },
      ]
    },
  },
  {
    path: '/admin/add-staff',
    name: 'AddStaff',
    component: () => import('./AddStaff.vue'),
    meta: {
      title: '新增员工',
      parent: '组织管理',
      activePath: '/admin/organization',
      navMenu: [
        { title: '业务管理', path: '', },
        { title: '组织管理', path: '/admin/organization', },
      ]
    },
  },
  {
    path: '/admin/edit-staff/:id',
    name: 'EditStaff',
    component: () => import('./EditStaff.vue'),
    meta: {
      title: '编辑员工',
      parent: '组织管理',
      activePath: '/admin/organization',
      navMenu: [
        { title: '业务管理', path: '', },
        { title: '组织管理', path: '/admin/organization', },
      ]
    },
  },
  {
    path: '/admin/edit-developer/:id?',
    name: 'EditDeveloper',
    component: () => import('./EditDeveloper.vue'),
    meta: {
      title: '开发商编辑',
      parent: '组织管理',
      activePath: '/admin/organization',
      navMenu: [
        { title: '业务管理', path: '', },
        { title: '组织管理', path: '/admin/organization', },
      ]
    },
  },
  {
    path: '/admin/edit-dealer/:id?',
    name: 'EditDealer',
    component: () => import('./EditDealer.vue'),
    meta: {
      title: '经销商编辑',
      parent: '组织管理',
      activePath: '/admin/organization',
      navMenu: [
        { title: '业务管理', path: '', },
        { title: '组织管理', path: '/admin/organization', },
      ]
    },
  },
]
