export default [
  {
    path: '/admin/material',
    name: 'materialList',
    component: () => import('./List.vue'),
    meta: {title: '项目物料', parent: '业务管理'},
  },
  {
    path: '/admin/material/info/:id',
    name: 'materialInfo',
    component: () => import('./Info.vue'),
    meta: {
      title: '物料详情',
      parent: '业务管理',
      activePath: '/admin/material',
      navMenu: [
        { title: '业务管理', path: '', },
        { title: '项目物料', path: '/admin/material', },
      ]
    },
  },
  {
    path: '/admin/material/edit/:id',
    name: 'materialEdit',
    component: () => import('./Edit.vue'),
    meta: {
      title: '物料上传',
      parent: '业务管理',
      activePath: '/admin/material',
      navMenu: [
        { title: '业务管理', path: '', },
        { title: '项目物料', path: '/admin/material', },
      ]
    },
  },
]