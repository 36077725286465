export default [
  {
    path: '/admin/settlement',
    name: 'settlementList',
    component: () => import('./List.vue'),
    meta: {title: '结算管理', parent: '业务管理'},
  },
  {
    path: '/admin/settlement/info/:id',
    name: 'settlementInfo',
    component: () => import('./Info.vue'),
    meta: {
      title: '结算详情',
      parent: '业务管理',
      activePath: '/admin/settlement',
      navMenu: [
        { title: '业务管理', path: '', },
        { title: '结算管理', path: '/admin/settlement', },
      ]
    },
  },
  {
    path: '/admin/settlement/edit/:id',
    name: 'settlementEdit',
    component: () => import('./Edit.vue'),
    meta: {
      title: '结算申请',
      parent: '业务管理',
      activePath: '/admin/settlement',
      navMenu: [
        { title: '业务管理', path: '', },
        { title: '结算管理', path: '/admin/settlement', },
      ]
    },
  },
]