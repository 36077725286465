<template>
  <div :class="[{'is-collapse': isCollapse}]" class="sidebar-container">
    <div class="sidebar-header">
      <img v-show="isCollapse" alt="" class="sidebar-logo" src="@/assets/img/login/logo-header.png">
      <img v-show="!isCollapse" alt="" class="sidebar-logo-shrink" src="@/assets/img/login/logo-icon.png">
    </div>
    <el-scrollbar class="sidebar-menu" wrap-class="sidebar-menu-scrollbar-wrapper">
      <el-menu :collapse="!isCollapse" :router="true"
               :unique-opened="true"
               :background-color="'#374656'"
               :text-color="'#B4B9BF'"
               :active-text-color="'#FFFFFF'"
               class="sidebar-el-menu"
               :default-active="defaultActive">
        <template v-for="(item, index) in menus">
          <template v-if="item.child && item.child.length > 0" >
            <el-submenu :index="index + ''" :key="index">
              <div slot="title">
                <i :class="item.icon" class="menu-icon"></i>
                <span slot="title">{{ item.name }}</span>
              </div>
              <el-menu-item-group>
                <template v-for="(menu, menuIndex) in item.child">
                  <el-menu-item :index="menu.url" :key="menuIndex"
                                class="my-menu-active"
                                style="color:#adadad;">{{
                    menu.name }}
                  </el-menu-item>
                </template>
              </el-menu-item-group>
            </el-submenu>
          </template>
          <template v-else>
            <el-menu-item :index="item.url" :key="index">
              <i :class="item.icon" class="menu-icon"></i>
              <span slot="title">{{ item.name }}</span>
            </el-menu-item>
          </template>
        </template>
      </el-menu>
    </el-scrollbar>
    <div class="sidebar-footer">
      <div class="sidebar-collapse-btn">
        <i :class="isCollapse ? 'el-icon-s-fold' : 'el-icon-s-unfold'" @click="changeCollapse"></i>
      </div>
    </div>
  </div>
</template>

<script>
import {APP_NAME} from "@/utils/config"

export default {
  name: "Sidebar",
  props: {
    menus: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      app_name: APP_NAME,
      isCollapse: true,
      defaultActive: null,
    }
  },
  watch: {
    '$route'(to) {
      this.defaultActive = to?.meta?.activePath || to.path
    }
  },
  created() {

  },
  mounted() {
    this.defaultActive = this.$route?.meta?.activePath || this.$route.path
  },
  methods: {
    changeCollapse() {
      this.isCollapse = !this.isCollapse
    }
  },
}
</script>

<style lang="scss" scoped>
.is-opened{
  .el-submenu__title{
    .menu-icon{
      color: #0D6CE4!important;
      text-shadow: 0 0 5px #0D6CE4;
    }
  }
}
</style>

<style lang="scss">
.sidebar-container{
  .is-opened{
    .el-submenu__title{
      background-color: #1C252F!important;
    }
  }
}

.sidebar-container {
  width: 48px;
  //height: calc(100vh - 48px);
  transition: width .28s linear;
  background-color: #384757;
  color: #FFFFFF;
  display: flex;
  flex-direction: column;
  z-index: 9;

  &.is-collapse {
    width: 208px;

    .sidebar-menu-scrollbar-wrapper {
      width: 208px;
    }
  }

  .sidebar-header {
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
    white-space: nowrap;

    .sidebar-logo {
      width: 152px;
      padding: 10px;
      object-fit: fill;
    }

    .sidebar-logo-shrink {
      width: 40px;
      object-fit: fill;
    }

    .sidebar-title {
      font-size: 16px;
      font-weight: bold;
      line-height: 48px;
      color: #FFFFFF;
    }
  }

  .sidebar-menu {
    flex: 1;
    padding: 0 0;
    width: 100%;
    border-right: none;

    .el-menu-item {
      background-color: #1E2731!important;
    }
    .el-menu-item:hover{
      outline: 0 !important;
      color: #ffffff !important;
    }
    //.el-menu-item.is-active {
    //  color: #fff !important;
    //  background: #409EFF !important;
    //}
    //.el-submenu__title:focus, .el-submenu__title:hover{
    //  outline: 0 !important;
    //  color: #409EFF !important;
    //  background: none !important;
    //}

    ::v-deep .sidebar-menu-scrollbar-wrapper {
      overflow-x: hidden !important;
    }

    .sidebar-menu-scrollbar-wrapper::-webkit-scrollbar {
      width: 0 !important
    }

    .sidebar-el-menu {
      border-right: unset;

      .el-menu-item.is-active {
        background-color: $--color-primary !important;
      }
    }

    .el-menu--collapse {
      width: 48px;
    }

    .menu-icon {
      width: 1em;
      text-align: center;
      margin-right: 5px;
      color: white !important;
    }
  }

  .sidebar-footer {
    height: 48px;

    .sidebar-collapse-btn {
      width: 48px;
      line-height: 48px;
      font-size: 26px;
      text-align: center;
      color: #FFFFFFA5;
    }
  }
}

.sidebar-container .sidebar-menu .sidebar-el-menu .el-menu-item.is-active {
  background-color: #252D36 !important;
  color: #ffffff !important;

  position: relative;
  &::after{
    content: ' ';
    position: absolute;
    right: 0;
    top: 0;
    display: block;
    width: 5px;
    height: 100%;
    background-color: #0D6CE4;
  }
}

.el-submenu .el-menu-item {
  height: 48px !important;
  line-height: 48px !important;
  padding: 0 45px;
  min-width: 171px !important;
}

.el-submenu__title {
  height: 56px !important;
  line-height: 56px !important;
}

.el-menu-item-group__title {
  padding: 0 0 0 20px !important;
}
</style>

<style>
.sidebar-el-menu.el-menu.el-menu--collapse {
  width: 48px;
}

.sidebar-el-menu.el-menu > .el-menu-item, .sidebar-el-menu.el-menu > .el-submenu .el-submenu__title {
  padding-left: 17px !important;
  text-align: left;
  color: #fff !important;
}

.sidebar-el-menu.el-menu.el-menu--collapse .el-menu-item {
  padding: 0 !important;
  text-align: center;
}

.sidebar-el-menu.el-menu.el-menu--collapse .el-menu-item .el-tooltip,
.sidebar-el-menu.el-menu.el-menu--collapse .el-submenu .el-submenu__title {
  padding: 0 !important;
  text-align: center;
}
</style>
